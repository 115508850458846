<template>
  <div class="container-fluid bg-container"></div>
  <div class="container specific-form-container">
    <BackRow />
    <FormRow />
  </div>
</template>

<script>
import FormRow from '@/components/specific/FormRow';
import BackRow from '@/components/utils/BackRow';

export default {
  name: 'Specific',
  components: {
    FormRow,
    BackRow,
  },
};
</script>

<style lang="scss">
.specific-form-container {
  transform: translateY(-17rem);
  margin-bottom: -14rem;
}
</style>
