<template>
  <div class="row specific-row mt-4">
    <div class="card-blue-static">
      <!-- Products -->
      <div class="col-12">
        <h3>What sectors is your company in?</h3>
        <el-select
          v-model="selectedSectors"
          multiple
          filterable
          placeholder="Please select one or more sector(s)"
          @change="handleSectors"
          size="large"
        >
          <el-option
            v-for="item in sectors"
            :key="item.id"
            :label="item.content"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- Components -->
      <div class="col-12">
        <h3>What components does your company use and/or produce?</h3>
        <el-select
          v-model="selectedComponents"
          multiple
          filterable
          placeholder="Please select one or more component(s)"
          @change="handleComponents"
          size="large"
        >
          <el-option
            v-for="item in components"
            :key="item.id"
            :label="item.content"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- Materials -->
      <div class="col-12">
        <h3>Which raw materials does your company use and/or produce?</h3>
        <el-select
          v-model="selectedMaterials"
          multiple
          filterable
          placeholder="Please select one or more material(s)"
          size="large"
        >
          <el-option
            v-for="item in materials"
            :key="item.id"
            :label="item.content"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-12 mt-4">
        <div class="btn-group btn-right">
          <button class="reset-btn" @click="resetOptions">Reset</button>
          <button class="submit-btn" @click="handleSubmit">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import Api from '@/utils/services/Api';

export default {
  name: 'MultipleSelect',
  props: ['select'],
  emits: ['isSelected'],
  data() {
    return {
      sectors: [],
      components: [],
      materials: [],
      selectedSectors: [],
      selectedComponents: [],
      selectedMaterials: [],
    };
  },
  async created() {
    localStorage.clear();
    await this.getAll();
  },
  methods: {
    async getAll() {
      const { sectors, components, materials } = await Api.get(
        'results',
        'all'
      );
      this.sectors = sectors;
      this.components = components;
      this.materials = materials;
    },
    async resetOptions() {
      await this.getAll();
      this.selectedSectors = [];
      this.selectedComponents = [];
      this.selectedMaterials = [];
    },
    async handleSectors(sectors) {
      const { components, materials } = await Api.post('results', {type: 'sectors'}, {
        sectors: sectors,
        selectedComponents: this.selectedComponents,
        selectedMaterials: this.selectedMaterials,
      });

      this.selectedComponents = components;
      this.selectedMaterials = materials;
    },
    async handleComponents(components) {
      const materials = await Api.post('results', {type: 'components'}, {
        components: components,
        selectedMaterials: this.selectedMaterials,
      });
      this.selectedMaterials = materials;
    },
    handleSubmit() {
      const sectors = this.selectedSectors;
      const components = this.selectedComponents;
      const materials = this.selectedMaterials;

      if (!sectors.length && !components.length && !materials.length) {
        ElMessage({
          message: 'Please select at least an option',
          showClose: true,
          type: 'error',
          offset: 150,
        });
        return;
      }

      this.$store
        .dispatch('setResults', {
          sectors: sectors,
          components: components,
          materials: materials,
        })
        .then(() => {
          this.$router.push({ name: 'Results' });
        });
    },
  },
};
</script>

<style lang="scss">
.specific-row {
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  .card-blue-static {
    padding: 2rem 1rem;
    max-width: 95%;
    height: auto;
  }
  .col-12 {
    margin-bottom: 1.5rem;
    h3 {
      margin-bottom: 1.5rem;
    }
  }
  .col-12:last-child {
    margin-bottom: 0;
  }
  .el-select {
    width: 100%;
    font-size: 18px !important;
    &__tags-text {
      font-size: 16px !important;
    }
  }
}

@include bp-up(md) {
  .specific-row {
    min-height: 40rem;
    .el-select {
      width: 70%;
    }
  }
}

@include bp-up(lg) {
  .card-blue-static {
    min-height: auto;
    padding: 1.5rem 1rem;
  }
}
</style>
